import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import posed from 'react-pose';
import { Context } from 'store/provider';
import Box from '@material-ui/core/Box';
import Typeograpy from '@material-ui/core/Typography';
import ClearIcon from '@material-ui/icons/Clear';

import { withStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  menuWrapper: {
    backgroundColor: theme.palette.primary.main,
    position: 'fixed',
    top: 0,
    height: '100vh',
    zIndex: 2,
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    width: '30vw',
    '&.closed': {
      right: '-100%',
    },
    '&.open': {
      right: 0,
    },
    [theme.breakpoints.down('lg')]: {
      width: '30vw',
    },
    [theme.breakpoints.down('md')]: {
      width: '100vw',
      justifyContent: 'flex-start',
    },
  },
  divider: {
    width: '80%',
    '& hr': {
      backgroundColor: theme.palette.text.white,
    },
  },
  navItem: {
    textAlign: 'center',
    marginBottom: '0.8rem',
  },
  navLink: {
    transition: 'transform 0.3s ease-in-out',
    transform: 'scale(1)',
    textDecoration: 'none',
    display: 'inline-block',
    '&:hover': {
      transform: 'scale(1.05)',
      '& .nav-underline': {
        width: '100%',
      },
    },
  },
  menuUnderline: {
    height: '1px',
    backgroundColor: theme.palette.text.white,
    width: '0%',
    margin: '0 auto',
    display: 'block',
    transition: 'width 0.3s ease-in-out',
  },
  close: {
    backgroundColor: 'transparent',
    padding: 0,
    margin: 0,
    border: 0,
    textTransform: 'uppercase',
    cursor: 'pointer',
    transform: 'scale(1)',
    transition: 'transform 0.3s ease-in-out',
    outline: 0,
    '&:hover': {
      transform: 'scale(1.1)',
    },
  },
  icon: {
    color: theme.palette.text.white,
  },
  logo: {
    height: '10px',
  },
  developmentsMenu: {
    position: 'absolute',
    display: 'none',
    right: '0px',
    top: '0px',
    background: theme.palette.secondary.main,
    transform: 'translateX(100%)',
    padding: '50px',
    height: '100%',
    width: '100%',
    opacity: 0,
    overflowY: 'scroll',
    transition: 'opacity 0.3s ease-in-out',
    '&.open': {
      display: 'block',
      opacity: 1,
    },
  },
  list: {
    padding: 0,
    '& li': {
      listStyle: 'none',
    },
  },
  linkItem: {
    textDecoration: 'none',
    transition: 'color 0.3s ease-in-out',
    '&:hover': {
      color: theme.palette.background.border,
    },
  },
  linkItemUnderline: {
    textDecoration: 'underline',
  },
  underline: {
    textDecoration: 'underline',
  },
  light: {
    color: theme.palette.text.white,
    letterSpacing: '2.3px',
    lineHeight: '24px',
    textTransform: 'uppercase',
  },
  bold: {
    fontWeight: 600,
  },
  lg: {
    fontSize: '24px',
  },
  link: {
    textDecoration: 'none',
    textAlign: 'center',
  },
});

const Sidebar = posed.nav({
  open: { right: '0%', staggerChildren: 100 },
  closed: { right: '-100%' },
});

const NavItem = posed.li({
  open: { opacity: 1 },
  closed: { opacity: 0 },
});

const NavWrapper = ({ isOpen, classes }) => {
  const [state, dispatch] = useContext(Context);
  const groupMenuToggle = () => {
    dispatch({ type: 'SET_GROUP_MENU', payload: !state.groupmenu });
  };
  return (
    <Sidebar
      className={`${classes.menuWrapper} ${isOpen ? 'open' : 'closed'}`}
      pose={isOpen ? 'open' : 'closed'}
    >
      <Box pt={2} pl={2}>
        <Box
          component="button"
          onClick={groupMenuToggle}
          display="flex"
          alignItems="center"
          className={classes.close}
        >
          <Box mr={1}>
            <ClearIcon className={classes.icon} />
          </Box>
          <Typeograpy component="span" variant="subtitle2" color="secondary">
            Close
          </Typeograpy>
        </Box>
      </Box>
      <Box display="flex" alignItems="center" flexDirection="column">
        <Box>
          <a
            href="https://realestatecapitalholdings.com/"
            target="_blank"
            rel="noreferrer"
            className={classes.link}
          >
            <Typography
              component="span"
              variant="subtitle1"
              className={`${classes.light} ${classes.bold} ${classes.lg}`}
            >
              Real
              <br />
              Estate
            </Typography>
          </a>
        </Box>
        <Box mb={6}>
          <a
            href="https://realestatecapitalholdings.com/"
            target="_blank"
            rel="noopener noreferrer"
            className={classes.link}
          >
            <Typography
              component="span"
              variant="subtitle1"
              className={`${classes.light}`}
            >
              Capital
              <br />
              Holdings
            </Typography>
          </a>
        </Box>
        <Box mb={2}>
          <a
            href="http://www.hayfieldhomes.co.uk"
            target="_blank"
            rel="noopener noreferrer"
            className={classes.link}
          >
            <Typography
              component="span"
              variant="subtitle1"
              className={`${classes.light} ${classes.bold} ${classes.lg}`}
            >
              Hayfield
            </Typography>
          </a>
        </Box>
        <Box mb={2}>
          <a
            href="http://www.terrastrategic.co.uk"
            target="_blank"
            rel="noopener noreferrer"
            className={classes.link}
          >
            <Typography
              component="span"
              variant="subtitle1"
              className={`${classes.light} ${classes.bold} ${classes.lg}`}
            >
              Terra
            </Typography>
          </a>
        </Box>
        <Box>
          <a
            href="https://www.verdanthomes.co.uk/"
            rel="noopener noreferrer"
            target="_blank"
            className={classes.link}
          >
            <Typography
              component="span"
              variant="subtitle1"
              className={`${classes.light} ${classes.bold} ${classes.lg}`}
            >
              Verdant
            </Typography>
          </a>
        </Box>
      </Box>
      <Box />
    </Sidebar>
  );
};

NavWrapper.propTypes = {
  isOpen: PropTypes.bool,
  classes: PropTypes.object.isRequired,
};

function GroupNav(props) {
  const { classes, themeColor } = props;
  const [state] = useContext(Context);
  return (
    <NavWrapper
      isOpen={state.groupmenu}
      classes={classes}
      themeColor={themeColor}
    />
  );
}

GroupNav.propTypes = {
  classes: PropTypes.object.isRequired,
  themeColor: PropTypes.string,
};

export default withStyles(styles)(GroupNav);
